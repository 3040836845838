export const data = {
    skills: [
      {
        title: "JAVASCRIPT",
        imageUrl: "./images/javascript.svg"
      },
      {
        title: "NODEJS",
        imageUrl: "./images/nodejs.svg"
      },
      {
        title: "REACT",
        imageUrl: "./images/react.svg"
      },
      {
        title: "VSCODE",
        imageUrl: "./images/vscode.svg"
      },
      {
        title: "REDUX",
        imageUrl: "./images/redux.svg"
      },
      {
        title: "FIGMA",
        imageUrl: "./images/figma.svg"
      }
    ],
    projects: [
      {
        id: 1,
        title: "Pizza",
        imageUrl: "./images/pizza.png",
        description: {
          tr: "Kullanıcıların anasayfadan sipariş ver butonuna tıkladıktan sonra kolay bir şekilde opsiyonları seçip sipariş verebileceği bir pizza satış sitesi",
          en: "A pizza sales site where users can easily select options and order after clicking the order button from the homepage."
        },
        keywords: ["react", "redux", "vercel"],
        github: "https://github.com/ahmetyalcn/fsweb-s7-challenge-pizza.git",
        url: "https://pizza-demo1.vercel.app"
      },
      {
        id: 2,
        title: "Watchlist",
        imageUrl: "./images/watchlist.png",
        description: {
          tr: "Kullanıcıların film API 'ından gelen filmlerden, istediği filmi favorilerine ekleyip çıkarmasını sağlayan bir Watchlist sitesi",
          en: "A Watchlist site that allows users to add and remove movies from their favorites from movies coming from the movie API."
        },
        keywords: ["react", "redux", "vercel"],
        github: "https://github.com/ahmetyalcn/fsweb-s10g3-redux-watchlist.git",
        url: "https://watchlist-redux.vercel.app/"
      }
    ]
  };
  